import { render, staticRenderFns } from "./FormChangePassword.vue?vue&type=template&id=1bacbf31&scoped=true&"
import script from "./FormChangePassword.vue?vue&type=script&lang=js&"
export * from "./FormChangePassword.vue?vue&type=script&lang=js&"
import style0 from "./FormChangePassword.vue?vue&type=style&index=0&id=1bacbf31&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bacbf31",
  null
  
)

export default component.exports